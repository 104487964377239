import type { ReturnedRow } from 'database/bigQueryHelper';
import type { FilterAttributes } from 'database/queryHelper';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Header } from 'ui/Header';
import ModalFilter from './ModalFilter';
import { QueriesFiltersContext } from './state/state';

export default function AppHeader() {
  const router = useRouter();
  const routesWithFilter = ['/formation', '/cout'];
  const { data: session } = useSession();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { queries, filtersList, addFilterCount, filterCount } = useContext(QueriesFiltersContext);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const newSelectedFilter: FilterAttributes[] = filtersList
      .map((filter) => {
        const data = queries[`${filter.ref}`]?.returnedRows ?? [];
        const values: string | number | string[] | number[] = filter.value ?? [];
        const selectedFilters: FilterAttributes[] = [];
        if (Array.isArray(values)) {
          values.forEach((value: string | number) => {
            const row = data.find((d: ReturnedRow) => {
              if (d[`${filter.id}`]) {
                return d[`${filter.id}`]?.toString() === value.toString();
              }
              return false;
            });
            if (row) {
              selectedFilters.push({
                id: filter.id as string,
                label: filter.label as string,
                ref: filter.ref as string,
                name: row[`${filter.label}`] as string,
                value: value,
                is_applied: true,
              });
            }
          });
        } else {
          const row = data.find((d: ReturnedRow) => {
            if (d[`${filter.id}`]) {
              return d[`${filter.id}`]?.toString() === values.toString();
            }
            return false;
          });
          if (row) {
            selectedFilters.push({
              id: filter.id as string,
              label: filter.label as string,
              ref: filter.ref as string,
              name: row[`${filter.label}`] as string,
              value: filter.value,
              is_applied: true,
            });
          }
        }
        return selectedFilters;
      })
      .flat();
    addFilterCount(newSelectedFilter.length);
  }, [filtersList, queries, addFilterCount]);

  return (
    <>
      <Header
        title={''}
        icon={'dashboard-logo-atlas.svg'}
        textColor="text-white"
        backgroundColor={'bg-[#521E3E]'}
        iconSize={200}
        backgroundImage={'header-bar-atlas.jpg'}
        filterButton={routesWithFilter.includes(router.pathname) ? true : false}
        is_authenticated={session ? true : false}
        email={session?.user.email}
        username={session?.user.username ?? session?.user.email}
        name={session?.user.name ?? session?.user.email}
        login={!session ? () => signIn('azure-ad') : undefined}
        logout={session ? () => signOut() : undefined}
        openModal={openModal}
        filterCount={filterCount}
      />
      {isModalOpen && <ModalFilter closeModal={closeModal} />}
    </>
  );
}
